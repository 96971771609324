const isClientKeyTicket = (ticket) => {
  const spnameAndParams = ticket.split(':', 2);
  if (spnameAndParams.length < 2) {
    return false;
  }

  return spnameAndParams[1].split('').some(element => element === 'c');
};

export default isClientKeyTicket;
