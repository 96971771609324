/**
 * generate callback url
 */
export const getCallbackScheme = (callbackURL, status, ticket, reason, hw = false, ppx = 'wwp_', version = 2) => {
  let scheme = callbackURL;

  switch (version) {
    default:
    case 2: {
      const firstDelimiter = (scheme.indexOf('?') === -1) ? '?' : '&';

      scheme += `${firstDelimiter + encodeURIComponent(ppx)}version=2`;
      scheme += `&${encodeURIComponent(ppx)}ticket=${encodeURIComponent(ticket)}`;
      scheme += `&${encodeURIComponent(ppx)}status=${encodeURIComponent(status)}`;
      scheme += `&${encodeURIComponent(ppx)}reason=${encodeURIComponent(reason)}`;
      if (hw) {
        scheme += `&${encodeURIComponent(ppx)}hw=1`;
      }
    } break;
  } // switch version

  return scheme;
};

/**
* WWPass SPFE WebSocket connection
* @param {object} options
*
* options = {
*   'ticket': undefined, // stirng
*   'callback': function,
*   'callbackURL': undefined, //string
*   'development': false || 'string' , // work with another spfews.wwpass.* server
*   'log': function (message) || console.log, // another log handler
*   'echo': undefined
* }
*/
const wwpassWebSocket = (initialOptions) => {
  const defaultOptions = {
    ticket: undefined,
    callback: () => {},
    callbackURL: undefined,
    returnErrors: false,
    log: () => {},
    development: false,
    spfewsAddress: 'wss://spfews.wwpass.com',
    echo: undefined
  };
  const options = Object.assign({}, defaultOptions, initialOptions);

  if (!('WebSocket' in window)) {
    options.callback(400, 'WebSocket is not supported.', options.echo);
    return false;
  }

  const websocketurl = options.spfewsAddress;
  const socket = new WebSocket(websocketurl);
  const log = options.log;
  try {
    socket.onopen = () => {
      log(`Connected: ${websocketurl}`);
      const message = JSON.stringify({ ticket: options.ticket });
      log(`Sent message to server: ${message}`);
      socket.send(message);
    };

    socket.onclose = () => {
      log('Disconnected');
    };

    socket.onmessage = (message) => {
      log(`Message received from server: ${message.data}`);
      const response = JSON.parse(message.data);
      let status = response.code;
      const reason = response.reason;

      if ('clientKey' in response && options.catchClientKey) {
        options.catchClientKey(response.clientKey);
      }

      if (response.clientKey) {
        status = 100;
      }

      if (status === 100) { return; }
      if (status === 200 || options.returnErrors) {
        socket.close();

        if (options.callbackURL !== undefined) {
          const s = getCallbackScheme(
              options.callbackURL,
              status,
              options.ticket,
              (status === 200 ? 'OK' : reason),
              false,  // hardware token
              options.ppx,
              options.version
          );

          log(`Scheme returned --> ${s}`);
          window.location.href = s;
        }
      }
    };
  } catch (e) {
    log(e);
    if (options.callback) {
      options.callback(400, 'SPFE connection error', options.echo);
    }
  }

  return socket; // Object will use out of scope, yep. Just as planned.
};

class WWPassWebSocketPR {
  constructor(initialOptions) {
    const defaultOptions = {
      ticket: undefined,
      callback: () => {},
      callbackURL: undefined,
      returnErrors: false,
      log: () => {},
      spfewsAddress: 'wss://spfews.wwpass.com',

      resolve: () => {},
      reject: () => {}
    };

    const options = Object.assign({}, defaultOptions, initialOptions);
    this.options = options;
  }

  static factory() {
    return new WWPassWebSocketPR({});
  }

}

export {
  wwpassWebSocket,
  WWPassWebSocketPR
};
