import { wwpassWebSocket } from './qrcode/wwpass.websocket';
import { ab2str, str2ab, abToB64, b64ToAb } from './ab';
import { loadBuffer, encrypt, decrypt, importKey } from './crypto';

const buffer = [176, 178, 97, 142, 156, 31, 45, 30, 81, 210, 85, 14, 202, 203, 86, 240];
const iv = new Uint8Array(buffer);

class WWPassCrypto {
  constructor(ticket) {
    this.ticket = ticket;
    this.clientKey = null;
    this.queue = [];
  }

  process() {
    let job = null;

    // eslint-disable-next-line
    while (job = this.queue.shift()) {
      if (job.operation === 'decrypt') {
        decrypt({ name: 'AES-CBC', iv }, this.clientKey, job.encryptedData)
        .then(job.resolve)
        .catch(job.reject);
      } else if (job.operation === 'encrypt') {
        encrypt({ name: 'AES-CBC', iv }, this.clientKey, job.data)
        .then(job.resolve)
        .catch(job.reject);
      }
    }
  }

  setClientKey(encryptedClientKey) {
    this.encryptedClientKey = encryptedClientKey;
    const aKey = loadBuffer();
    if (aKey === undefined) {
      const job = this.queue.shift();
      job.reject('clientKeyNonce not found');
      return;
    }

    return importKey('raw', aKey, { name: 'AES-CBC' }, false, [
      'encrypt',
      'decrypt',
      'wrapKey',
      'unwrapKey'
    ])
    .then(clientKeyNonce => decrypt({ name: 'AES-CBC', iv }, clientKeyNonce, b64ToAb(this.encryptedClientKey)))
    .then(arrayBuffer => importKey('raw', arrayBuffer, { name: 'AES-CBC' }, false, [
      'encrypt',
      'decrypt',
      'wrapKey',
      'unwrapKey']))
    .then((key) => {
      this.clientKey = key;
      this.process();
    })
    .catch((err) => {
      const job = this.queue.shift();
      job.reject('Can\'t decrypt client key');
    });
  }

  getCK() {
    wwpassWebSocket({
      ticket: this.ticket,
      catchClientKey: (clientKey) => {
        this.setClientKey(clientKey);
      }
    });
  }

  bounceEncrypt(data, resolve, reject) {
    this.queue.push({
      operation: 'encrypt',
      data,
      resolve,
      reject
    });

    if (this.clientKey) {
      this.process();
    } else {
      this.getCK();
    }
  }

  encryptArrayBuffer(arrayBuffer) {
    return new Promise(
      (resolve, reject) => {
        this.bounceEncrypt(arrayBuffer, resolve, reject);
      }
    );
  }

  encryptString(string) {
    return new Promise(
      (resolve, reject) => {
        const wrapEncode = (data) => {
          resolve(abToB64(data));
        };

        this.bounceEncrypt(str2ab(string), wrapEncode, reject);
      }
    );
  }

  bounceDecrypt(encryptedData, resolve, reject) {
    this.queue.push({
      operation: 'decrypt',
      encryptedData,
      resolve,
      reject
    });

    if (this.clientKey) {
      this.process();
    } else {
      this.getCK();
    }
  }

  decryptArrayBuffer(encryptedArrayBuffer) {
    return new Promise(
      (resolve, reject) => {
        this.bounceDecrypt(encryptedArrayBuffer, resolve, reject);
      }
    );
  }

  decryptString(encryptedString) {
    return new Promise(
      (resolve, reject) => {
        const wrapDecode = (data) => {
          resolve(ab2str(data));
        };

        this.bounceDecrypt(b64ToAb(encryptedString), wrapDecode, reject);
      }
    );
  }

}

/*
 * Promise exp
 */

class WWPassCryptoPromise {
  constructor(ticket) {
    this.ticket = ticket;
    this.clientKey = null;
    this.queue = [];

    return new Promise((resolve, reject) => {
      wwpassWebSocket({
        ticket: this.ticket,
        catchClientKey: (clientKey) => {
          this.setClientKey(clientKey);
          resolve(this);
        }
      });
    });
  }

  process() {
    let job = null;

    // eslint-disable-next-line
    while(job = this.queue.shift()) {
      if (job.operation === 'decrypt') {
        decrypt({ name: 'AES-CBC', iv }, this.clientKey, job.encryptedData)
        .then(job.resolve);
      } else if (job.operation === 'encrypt') {
        encrypt({ name: 'AES-CBC', iv }, this.clientKey, job.data)
        .then(job.resolve);
      }
    }
  }

  setClientKey(encryptedClientKey) {
    this.encryptedClientKey = encryptedClientKey;
    const aKey = loadBuffer();
    return importKey('raw', aKey, { name: 'AES-CBC' }, false, [
      'encrypt',
      'decrypt',
      'wrapKey',
      'unwrapKey'
    ])
    .then((clientKeyNonce) => {
      return decrypt({ name: 'AES-CBC', iv }, clientKeyNonce, b64ToAb(this.encryptedClientKey));
    })
    .then((arrayBuffer) => {
      return importKey('raw', arrayBuffer, { name: 'AES-CBC' }, false, [
        'encrypt',
        'decrypt',
        'wrapKey',
        'unwrapKey'])
    })
    .then((key) => {
      this.clientKey = key;
      this.process();
    })
    .catch((err) => {

    });
  }

  getCK() {
    wwpassWebSocket({
      ticket: this.ticket,
      catchClientKey: (clientKey) => {
        this.setClientKey(clientKey);
      }
    });
  }

  bounceEncrypt(data, resolve) {
    this.queue.push({
      operation: 'encrypt',
      data,
      resolve
    });

    if (this.clientKey) {
      this.process();
    } else {
      this.getCK();
    }
  }

  encryptArrayBuffer(arrayBuffer) {
    return new Promise(
      (resolve) => {
        this.bounceEncrypt(arrayBuffer, resolve);
      }
    );
  }

  encryptString(string) {
    return new Promise(
      (resolve) => {
        const wrapEncode = (data) => {
          resolve(abToB64(data));
        };

        this.bounceEncrypt(str2ab(string), wrapEncode);
      }
    );
  }

  bounceDecrypt(encryptedData, resolve) {
    this.queue.push({
      operation: 'decrypt',
      encryptedData,
      resolve
    });

    if (this.clientKey) {
      this.process();
    } else {
      this.getCK();
    }
  }

  decryptArrayBuffer(encryptedArrayBuffer) {
    return new Promise(
      (resolve) => {
        this.bounceDecrypt(encryptedArrayBuffer, resolve);
      }
    );
  }

  decryptString(encryptedString) {
    return new Promise(
      (resolve) => {
        const wrapDecode = (data) => {
          resolve(ab2str(data));
        };

        this.bounceDecrypt(b64ToAb(encryptedString), wrapDecode);
      }
    );
  }

}

export { WWPassCrypto, WWPassCryptoPromise };
