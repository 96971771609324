const PageVisibility = function () {
  this.visible = true;
  this.hidden = 'hidden';
  if (this.hidden in document) {
    document.addEventListener('visibilitychange', this);
  } else if ((this.hidden = 'mozHidden') in document) {
    document.addEventListener('mozvisibilitychange', this);
  } else if ((this.hidden = 'webkitHidden') in document) {
    document.addEventListener('webkitvisibilitychange', this);
  } else if ((this.hidden = 'msHidden') in document) {
    document.addEventListener('msvisibilitychange', this);
  } else if ('onfocusin' in document) {
    document.onfocusin = document.onfocusout = this;
  } else {
    window.onpageshow = window.onpagehide = window.onfocus = window.onblur = this;
  }
};
PageVisibility.prototype.handleEvent = function (ev) {
  let visible;
  let v = true,
    h = false,
    evMap = { focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h };
  ev = ev || window.event;
  if (ev.type in evMap) {
    visible = evMap[ev.type];
  } else {
    visible = !document[this.hidden];
  }
  if (this.visible ^ visible) {
    if (visible && (this.onVisible != undefined)) {
      this.onVisible();
      this.onVisible = undefined;
    }
  }
  this.visible = visible;
};
PageVisibility.prototype.whenVisible = function (callable) {
  const call = function () {
    if (typeof (callable) === 'object') {
      callable.handleEvent.call(callable);
    } else {
      callable();
    }
  };
  if (this.visible) {
    call();
  } else {
    this.onVisible = call;
  }
};

export default PageVisibility;
