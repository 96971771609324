/* Configuration */
const WWPass_Interfaces = ['nm', 'plugin'];  // list of interfaces in order of preference
const WWPass_DebugJs = true;
const WWPass_JsVersion = '2.4.19';
const WWPass_Render_Error = true; // false if don't render dialog (render errors manualy)
let _wwpass_log = () => {};

/* Constants */
/* Status codes */
const WWPass_CONTINUE = 100;

const WWPass_OK = 200;
const WWPass_OK_Msg = 'OK';

const WWPass_INTERNAL_ERROR = 400;
const WWPass_ALREADY_PERSONALIZED = 401;
const WWPass_PASSWORD_MISMATCH = 402;
const WWPass_PASSWORD_LOCKOUT = 403;
const WWPass_WRONG_KEY = 404;
const WWPass_WRONG_KEY_SECOND = 405;
const WWPass_NOT_A_KEY = 406;
const WWPass_NOT_A_KEY_SECOND = 407;
const WWPass_KEY_DISABLED = 408;
const WWPass_NOT_ALLOWED = 409;
const WWPass_BLANK_TOKEN = 410;
const WWPass_BLANK_SECOND_TOKEN = 411;
const WWPass_ACTIVITY_PROFILE_LOCKED = 412;
const WWPass_SSL_REQUIRED = 413;
const WWPass_BLANK_NORMAL_TOKEN = 414;
const WWPass_BLANK_SECOND_NORMAL_TOKEN = 415;
const WWPass_BLANK_MASTER_TOKEN = 416;
const WWPass_BLANK_SECOND_MASTER_TOKEN = 417;
const WWPass_NOT_ACTIVATED_TOKEN = 418;
const WWPass_NOT_ACTIVATED_SECOND_TOKEN = 419;
const WWPass_WRONG_KEY_SET = 420;
const WWPass_NO_VERIFIER = 421;
const WWPass_INCOMPLETE_KEYSET = 422;
const WWPass_INVALID_TICKET = 423;
const WWPass_SAME_TOKEN = 424;
const WWPass_NO_RECOVERY_INFO = 425;
const WWPass_BAD_RECOVERY_REQUEST = 426;
const WWPass_RECOVERY_FAILED = 427;

const WWPass_TERMINAL_ERROR = 500;
const WWPass_TERMINAL_NOT_FOUND = 501;
const WWPass_TERMINAL_BAD_REQUEST = 502;
const WWPass_NO_CONNECTION = 503;
const WWPass_NETWORK_ERROR = 504;
const WWPass_PROTOCOL_ERROR = 505;
const WWPass_UNKNOWN_HANDLER = 506;
const WWPass_TERMINAL_CANCELED = 590;

const WWPass_TIMEOUT = 600;
const WWPass_USER_REJECT = 603;

const WWPass_NO_AUTH_INTERFACES_FOUND = 604;
const WWPass_NO_AUTH_INTERFACES_FOUND_Msg = 'No WWPass SecurityPack is found on your computer or WWPass Browser Plugin is disabled';
const WWPass_UNSUPPORTED_PLATFORM = 606;
const WWPass_UNSUPPORTED_PLATFORM_Msg_Tmpl = 'WWPass authentication is not supported on';

const WWPass_TERMINAL_TIMEOUT = 605;
/* end of status codes*/

const WWPass_KEY_TYPE_ANY = '';
const WWPass_KEY_TYPE_PASSKEY = 'passkey';
const WWPass_KEY_TYPE_SERVICE = 'service';
const WWPass_KEY_TYPE_BLANK = 'blank';
const WWPass_KEY_TYPE_BLANKPASSKEY = 'blankpasskey';
const WWPass_KEY_TYPE_BLANKSERVICE = 'blankservice';
const WWPass_KEY_TYPE_BLANK2NDSERVICE = 'blank2ndservice';
const WWPass_KEY_TYPE_DEFAULT = WWPass_KEY_TYPE_PASSKEY;

/* Plugin specific functions */
const _WWPass_PLUGIN_OBJECT_ID = '_wwpass_plugin';
const _WWPass_PLUGIN_MIME_TYPE = 'application/x-wwauth';
const _WWPass_PLUGIN_TIMEOUT = 10000;
const _WWPass_REDUCED_PLUGIN_TIMEOUT = 1000;
const _WWPass_PLUGIN_AUTH_KEYTYPE_REVISION = 9701;
let _WWPass_Plugin_Instance = null;
let _WWPass_Plugin_Version_String;
let _WWPass_Plugin_Revision;
let _WWPass_Plugin_Load_Timer = new Object();
let _WWPass_Plugin_Shows_Errors = false;
/* Extension specific constants */
let _WWPass_EXTENSION_POLL_TIMEOUT = 200;
let _WWPass_EXTENSION_POLL_ATTEMPTS = 15;
let _WWPass_EXTENSION_NOT_INSTALLED = false;

export const STATUS = {
  OK: 200
};

/* External deps */
const prefix = (window.location.protocol === 'https:') ? 'https:' : 'http:';
const _WWPass_CSS = `${prefix}//cdn.wwpass.com/packages/wwpass.js/2.4/wwpass.js.css`;

function isNativeMessaging() {
  const userAgent = navigator.userAgent;

  let re = /Firefox\/([0-9]+)\./;
  let match = userAgent.match(re);
  if (match && match.length > 1) {
    const version = match[1];
    if (Number(version) >= 51) {
      return 'Firefox';
    }
  }

  re = /Chrome\/([0-9]+)\./;
  match = userAgent.match(re);
  if (match && match.length > 1) {
    const version = match[1];
    if (Number(version) >= 45) {
      return 'Chrome';
    }
  }

  return false;
}

export const setWWPassLog = (log) => {
  _wwpass_log = log;
};

function isNativeMessagingExtensionReady() {
  return (document.querySelector('meta[property="wwpass:extension:version"]') ||
                        document.getElementById('_WWAuth_Chrome_Installed_')) !== null;
}

function _wwpass_plugin_shows_errors(pluginVersionString) {
  if (typeof (pluginVersionString) === 'string') {
    const pluginVersion = pluginVersionString.split('.');
    // let s = 0;
    for (const s in pluginVersion) {
      pluginVersion[s] = parseInt(pluginVersion[s], 10);
    }

    if (pluginVersion.length === 3) {
      if (
          (pluginVersion[0] > 2) ||
          (pluginVersion[0] === 2 && pluginVersion[1] > 4) ||
          (pluginVersion[0] === 2 && pluginVersion[1] === 4 && pluginVersion[2] >= 1305)
      ) {
        return true;
      }
    }
  }
  return false;
}
function _wwpass_plugin_wrap_callback(callback) {
  if (!_WWPass_Plugin_Shows_Errors) {
    return function (code, ticketOrMessage) {
      if (code != WWPass_OK && code != WWPass_USER_REJECT) {
        const message = `<p><b>A error has occured:</b> ${
                ticketOrMessage}</p>` +
                `<p><a href="https://support.wwpass.com/?topic=${code}">Learn more</a></p>`;
        _wwpass_show_error(message, 'WWPass Error',
                () => {
                  callback(code, ticketOrMessage);
                });
      } else {
        callback(code, ticketOrMessage);
      }
    };
  }
  return callback;
}
function _wwpass_plugin_loaded() {
  _wwpass_log('%s: plugin loaded', 'arguments.callee.name');
  clearTimeout(_WWPass_Plugin_Load_Timer.timer);
  try {
    _WWPass_Plugin_Version_String = _WWPass_Plugin_Instance.version;
    _WWPass_Plugin_Revision = parseInt(_WWPass_Plugin_Instance.version.split('.')[2], 10);
    _WWPass_Plugin_Shows_Errors = _wwpass_plugin_shows_errors(_WWPass_Plugin_Version_String);
  } catch (err) {
  }
  _WWPass_Plugin_Load_Timer.onSuccess();
}

// ==>

function _wwpass_do_with_plugin(callable) {
  _wwpass_log('%s: called', 'arguments.callee.name');
  if (_WWPass_Plugin_Instance) {
    _wwpass_log('%s: plugin is already initialized', 'arguments.callee.name');
    callable();
  } else {
    const junkBrowser = (navigator.mimeTypes.length === 0);
    const pluginInstalled = (navigator.mimeTypes[_WWPass_PLUGIN_MIME_TYPE] != undefined);
    const timeout = (junkBrowser) ? _WWPass_REDUCED_PLUGIN_TIMEOUT : _WWPass_PLUGIN_TIMEOUT;
    if (pluginInstalled || junkBrowser) {
      _wwpass_log('%s: trying to create plugin instance(junkBrowser=%s, timeout=%d)',
                'arguments.callee.name', junkBrowser, timeout);
      const pluginHtml = `<object id='${_WWPass_PLUGIN_OBJECT_ID}' width=0 height=0 type='${_WWPass_PLUGIN_MIME_TYPE
                }'><param name='onload' value='_wwpass_plugin_loaded'/></object>`;
      const pluginDiv = document.createElement('div');
      pluginDiv.setAttribute('style', 'position: fixed; left: 0; top:0; width: 1px; height: 1px; z-index: -1; opacity: 0.01');
      document.body.appendChild(pluginDiv);
      pluginDiv.innerHTML += pluginHtml;
      _WWPass_Plugin_Instance = document.getElementById(_WWPass_PLUGIN_OBJECT_ID);
      _WWPass_Plugin_Load_Timer.onSuccess = callable;
      const t = this;
      _WWPass_Plugin_Load_Timer.timer = setTimeout(
                    (() => {
                      _WWPass_Plugin_Instance = null;
                      t.onError();
                    }),
                    _WWPass_PLUGIN_TIMEOUT);
    } else {
      _wwpass_log('%s: no suitable plugins installed', 'arguments.callee.name');
      this.onError();
    }
  }
}

// <==

function _wwpass_random_id() {
  return (((1 + Math.random()) * 0x100000000) | 0).toString(16).substring(1);
}
function _wwpass_timed_poll(args) {
  let condition = args.condition;
  if (typeof (condition) === 'function') {
    condition = condition();
  }
  if (condition) {
    args.onCondition();
  } else {
    let attempts = args.attempts || 0;
    if (attempts--) {
      const timeout = args.timeout || 100;
      setTimeout((function (p) { return function () { _wwpass_timed_poll(p); }; }(
        { timeout,
          attempts,
          condition: args.condition,
          onCondition: args.onCondition,
          onTimeout: args.onTimeout
        })), timeout);
    } else {
      args.onTimeout();
    }
  }
}
function _wwpass_send_chrome_message(func, args, callback) {
  if (_WWPass_EXTENSION_NOT_INSTALLED) {
    _wwpass_log('%s: chrome native messaging extension is not installed', 'arguments.callee.name');
    this.onError();
  } else {
    _wwpass_timed_poll({
      timeout: _WWPass_EXTENSION_POLL_TIMEOUT,
      attempts: _WWPass_EXTENSION_POLL_ATTEMPTS,
      condition: isNativeMessagingExtensionReady,
      onCondition() {
        const id = _wwpass_random_id();
        window.postMessage({
          type: '_WWAuth_Message',
          src: 'client',
          id,
          func,
          args: args ? JSON.parse(JSON.stringify(args)) : args
        }, '*');
        window.addEventListener('message', function onMessageCallee(event) {
          if (event.data.type == '_WWAuth_Message' && event.data.src == 'plugin' && event.data.id == id) {
            if (event.data.code == WWPass_NO_AUTH_INTERFACES_FOUND) {
              const message = '<p>No Security Pack is found on your computer or WWPass&nbsp;native&nbsp;host is not responding.</p><p>To install Security Pack visit <a href="https://ks.wwpass.com/download/">Key Services</a> </p><p><a href="https://support.wwpass.com/?topic=604">Learn more...</a></p>';
              _wwpass_show_error(message, 'WWPass Error',
                            () => {
                              callback(event.data.code, event.data.ticketOrMessage);
                            });
            } else {
              callback(event.data.code, event.data.ticketOrMessage);
            }
            window.removeEventListener('message', onMessageCallee, false);
          }
        }, false);
      },
      onTimeout: (function (t) {
        return function () {
          _WWPass_EXTENSION_NOT_INSTALLED = true;
          _wwpass_log('%s: chrome native messaging extension is not installed', 'arguments.callee.name');
          t.onError();
        };
      }(this))
    });
  }
}
function _wwpass_nm_auth(ticket, keytype, callback) {
  _wwpass_log('%s: called', 'arguments.callee.name');
  _wwpass_send_chrome_message.call(this, 'auth', [ticket, keytype], callback);
}
function _wwpass_nm_command(commandName, keytype, callback) {
  _wwpass_log('%s: called', 'arguments.callee.name');
  _wwpass_send_chrome_message.call(this, 'do_op', [commandName], callback);
}
function _wwpass_nm_execute(request) {
  _wwpass_log('%s: called', 'arguments.callee.name');
  request.uri = { domain: window.location.hostname, protocol: window.location.protocol };
  _wwpass_send_chrome_message.call(this, 'exec', [request], request.callback);
}
function _wwpass_nm_on_key_removed(callback) {
  _wwpass_log('%s: called', 'arguments.callee.name');
  _wwpass_send_chrome_message.call(this, 'on_key_rm', undefined, callback);
}
function _wwpass_nm_test(callback) {
  _wwpass_send_chrome_message.call(this, 'test', undefined, () => {
    callback(WWPass_OK, 'nm');
  });
}
function _wwpass_plugin_auth(ticket, keytype, callback) {
  _wwpass_log('%s: called', 'arguments.callee.name');
  const wrappedCallback = _wwpass_plugin_wrap_callback(callback);
  _wwpass_do_with_plugin.call(this, (() => {
    if (_WWPass_Plugin_Revision < _WWPass_PLUGIN_AUTH_KEYTYPE_REVISION) {
      _WWPass_Plugin_Instance.authenticate(ticket, wrappedCallback);
    } else {
      _WWPass_Plugin_Instance.authenticate(ticket, wrappedCallback, keytype);
    }
  }));
}
function _wwpass_plugin_command(commandName, keytype, callback) {
  _wwpass_log('%s: called, command name is "%s"', 'arguments.callee.name', commandName);
  const wrappedCallback = _wwpass_plugin_wrap_callback(callback);
  _wwpass_do_with_plugin.call(this, (() => {
    _WWPass_Plugin_Instance.do_operation(commandName, wrappedCallback);
  }));
}
function _wwpass_plugin_execute(request) {
  _wwpass_log('%s: called, operation name is "%s"', 'arguments.callee.name', request.operation || null);
  _wwpass_do_with_plugin.call(this, (function () {
    if (_WWPass_Plugin_Instance.execute != undefined) {
      request.callback = _wwpass_plugin_wrap_callback(request.callback);
      _WWPass_Plugin_Instance.execute(request);
    } else if (request.operation == 'auth') {
      _wwpass_plugin_auth.call(this, request.ticket, request.firstKeyType || WWPass_KEY_TYPE_DEFAULT, request.callback);
    } else {
      _wwpass_plugin_command.call(this, request.operation, request.firstKeyType || WWPass_KEY_TYPE_DEFAULT, request.callback);
    }
  }));
}
function _wwpass_plugin_on_key_removed(callback) {
  _wwpass_do_with_plugin.call(this, (() => {
    _wwpass_log('_wwpass_plugin_on_key_removed called');
    _WWPass_Plugin_Instance.on_key_removed(callback);
  }));
}
function _wwpass_plugin_test(callback) {
  _wwpass_do_with_plugin.call(this, (() => {
    callback(WWPass_OK, 'plugin');
  }));
}
function _wwpass_show_error(message, title, on_close_callback) {
  if (!WWPass_Render_Error) {
    on_close_callback();
    return false;
  }

  if (!document.getElementById('_wwpass_css')) {
    const l = document.createElement('link');
    l.id = '_wwpass_css';
    l.rel = 'stylesheet';
    l.href = _WWPass_CSS;
    document.head.appendChild(l);
  }

  const dlg = document.createElement('div');
  dlg.id = '_wwpass_err_dlg';

  const dlg_close = document.createElement('span');
  dlg_close.innerHTML = 'Close';
  dlg_close.id = '_wwpass_err_close';

  const header = document.createElement('h1');
  header.innerHTML = title;

  const text = document.createElement('div');
  text.innerHTML = message;


  dlg.appendChild(header);
  dlg.appendChild(text);
  dlg.appendChild(dlg_close);
  document.body.appendChild(dlg);

  document.getElementById('_wwpass_err_close').addEventListener('click', () => {
    const elem = document.getElementById('_wwpass_err_dlg');
    elem.parentNode.removeChild(elem);
    on_close_callback();
    return false;
  });
}

function _wwpass_no_software(code, onclose) {
  if (code == WWPass_NO_AUTH_INTERFACES_FOUND) {
    const client = isNativeMessaging();
    let message = '';
    if (client) {
      if (client === 'Chrome') {
        var returnURL = encodeURIComponent(window.location.href);
        message = '<p>The WWPass Authentication extension for Chrome is not installed or is disabled in browser settings.';
        message += '<p>Click the link below to install and enable the WWPass Authentication extension.';
        message += `<p><a href="https://chrome.wwpass.com/?callbackURL=${returnURL}">Install WWPass Authentication Extension</a>`;
      } else
            if (client === 'Firefox') {
                // Firefox
              var returnURL = encodeURIComponent(window.location.href);
              message = '<p>The WWPass Authentication extension for Firefox is not installed or is disabled in browser settings.';
              message += '<p>Click the link below to install and enable the WWPass Authentication extension.';
              message += `<p><a href="https://firefox.wwpass.com/?callbackURL=${returnURL}">Install WWPass Authentication Extension</a>`;
            } else {
                // Wait Edge
            }
    } else {
      message = '<p>No Security Pack is found on your computer or WWPass&nbsp;Browser&nbsp;Plugin is disabled.</p><p>To install Security Pack visit <a href="https://ks.wwpass.com/download/">Key Services</a> or check plugin settings of your browser to activate WWPass&nbsp;Browser&nbsp;Plugin.</p><p><a href="https://support.wwpass.com/?topic=604">Learn more...</a></p>';
    }
    _wwpass_show_error(message, 'WWPass &mdash; No Software Found', onclose);
  } else if (code == WWPass_UNSUPPORTED_PLATFORM) {
    _wwpass_show_error(_wwpass_message_for_platform(_wwpass_platform_name()), 'WWPass &mdash; Unsupported Platform', onclose);
  }
}

function _wwpass_platform_name() {
  const userAgent = navigator.userAgent;
  const knownPlatforms = ['Android', 'iPhone', 'iPad'];
  for (let i = 0; i < knownPlatforms.length; i++) {
    if (userAgent.search(new RegExp(knownPlatforms[i], 'i')) != -1) {
      return knownPlatforms[i];
    }
  }
  return null;
}
function _wwpass_message_for_platform(platformName) {
  return `${WWPass_UNSUPPORTED_PLATFORM_Msg_Tmpl} ${platformName}`;
}
function _wwpass_make_fn(interfaceName, funcName) {
  let func = null;
  try {
    if (funcName === 'auth') {
      func = interfaceName === 'nm' ? _wwpass_nm_auth : _wwpass_plugin_auth;
    } else if (funcName === 'execute') {
      func = interfaceName === 'nm' ? _wwpass_nm_execute : _wwpass_plugin_execute;
    } else if (funcName === 'on_key_removed') {
      func = interfaceName === 'nm' ? _wwpass_nm_on_key_removed : _wwpass_plugin_on_key_removed;
    } else {
      func = eval(`_wwpass_${interfaceName}_${funcName}`);
    }
  } catch (e) {
    _wwpass_log('%s: "%s" is not defined for "%s"', 'make_fn',
            funcName, interfaceName);
    return null;
  }
  return func;
}
function _wwpass_call(interfaces, funcName) {
  const platformName = _wwpass_platform_name();
  if (platformName !== null) {
    var callback = arguments[arguments.length - 1];
    _wwpass_no_software(WWPass_UNSUPPORTED_PLATFORM, () => {
      callback.call(null, WWPass_UNSUPPORTED_PLATFORM,
                    _wwpass_message_for_platform(platformName));
    });
    return;
  }
  let f = null;
  while (interfaces.length !== 0) {
    if ((f = _wwpass_make_fn(interfaces[0], funcName)) !== null) {
      break;
    }
    interfaces = interfaces.slice(1);
  }
  if (f === null) {
    var callback = arguments[arguments.length - 1];
    _wwpass_no_software(WWPass_NO_AUTH_INTERFACES_FOUND, () => {
      callback.call(null, WWPass_NO_AUTH_INTERFACES_FOUND,
                WWPass_NO_AUTH_INTERFACES_FOUND_Msg);
    });
    return;
  }
  f.onError = (function () {
    _wwpass_call.apply(this, this.onErrorArgs);
  });
  f.onErrorArgs =
        [interfaces.slice(1)].concat(Array.prototype.slice.call(arguments, 1));
  _wwpass_log('%s: about to call %s', 'arguments.callee.name', f.name);
  f.apply(f, Array.prototype.slice.call(arguments, 2));
}
/* public functions */
function _wwpass_default_cb(code, ticketOrMessage) {
  _wwpass_log('%s: code=%d ticketOrMessage="%s"', 'arguments.callee.name',
        code, ticketOrMessage);
}

export const wwpassAuth = function wwpassAuth(request) {
  if (typeof (request) === 'object') {
    request.operation = 'auth';
    return _wwpass_call(WWPass_Interfaces, 'execute', request, request.callback || _wwpass_default_cb);
  }

  // note: legacy code support, obsolete
  let ticket = arguments[0];
  if (arguments.length < 2) {
    throw new Error('Too few arguments to function');
  } else {
    ticket = arguments[0];
    if (arguments.length < 3) {
      var keytype = WWPass_KEY_TYPE_DEFAULT;
      var callback = arguments[1];
    } else {
      var keytype = arguments[1];
      var callback = arguments[2];
    }
    return _wwpass_call(WWPass_Interfaces, 'auth', ticket, keytype, callback || _wwpass_default_cb);
  }
};

export const wwpassCommand = function (commandName, keytype, callback) {
  if (arguments.length < 3) { /* no keytype specified */
    var callback = arguments[1];
    var keytype = WWPass_KEY_TYPE_DEFAULT;
  }
  return _wwpass_call(WWPass_Interfaces, 'command', commandName, keytype, callback || _wwpass_default_cb);
};

export const wwpassExecute = function (request) {
  return _wwpass_call(WWPass_Interfaces, 'execute', request, request.callback || _wwpass_default_cb);
};

export const onKeyRemoved = function (callback) {
  return _wwpass_call(WWPass_Interfaces, 'on_key_removed', callback || _wwpass_default_cb);
};

export const wwpassTest = function (callback) {
  return _wwpass_call(WWPass_Interfaces, 'test', callback || _wwpass_default_cb);
};

export const pluginPresent = function() {
    if (
        navigator.mimeTypes[_WWPass_PLUGIN_MIME_TYPE] ||
        document.querySelector('meta[property="wwpass:extension:version"]') ||
        document.getElementById("_WWAuth_Chrome_Installed_")
    ) {
        return true;
    }
    return false;
};

if (navigator.mimeTypes[_WWPass_PLUGIN_MIME_TYPE]) {
  WWPass_Interfaces.shift();
}

window._wwpass_plugin_loaded = _wwpass_plugin_loaded;
