import { wwpassQRCodeAuth, getJSON } from './qrcode/wwpass.qrcode';
import { getCallbackScheme } from './qrcode/wwpass.websocket';
import { getUniversalScheme } from './scheme';
import { abToB64 } from './ab';

import isClientKeyTicket from './ticket';
import {
  wwpassAuth,
  STATUS,
  setWWPassLog,
  onKeyRemoved,
  pluginPresent
} from './passkey/wwpass';
import {
  getClientKey,
  encodeClientKey,
  loadBuffer,
  saveBuffer
} from './crypto';
import { WWPassCrypto, WWPassCryptoPromise } from './wwpass.crypto';

import { version } from '../package.json';

const init = (initialOptions) => {
  const defaultOptions = {
    ticketURL: '',
    callbackURL: '',
    hw: false,
    ppx: 'wwp_',
    version: 2,
    log: () => {}
  };

  const options = Object.assign({}, defaultOptions, initialOptions);
  const passkeyButton = (typeof options.passkey === 'string') ? document.querySelector(options.passkey) : options.passkey;

  const qrcodeElement = (typeof options.qrcode === 'string') ? document.querySelector(options.qrcode) : options.qrcode;

  if ('log' in options && typeof options.log === 'function') {
    setWWPassLog(options.log);
  } else {
    setWWPassLog(() => {});
  }

  options.qrcode = qrcodeElement;

  passkeyButton.addEventListener('click', (e) => {
    getJSON(
      options.ticketURL,
      (json) => {
        const ticket = json.ticket || json.data;
        if (isClientKeyTicket(ticket)) {
          const key = loadBuffer();

          if (!key || !abToB64(key)) {
            const GCKresolve = (rawKey) => {
              saveBuffer(rawKey);

              wwpassAuth({
                ticket,
                callback: (status, reason) => {
                  const url = getCallbackScheme(
                    options.callbackURL,
                    status,
                    ticket,
                    (status === STATUS.OK ? 'OK' : reason),
                    true, // hw = true, hardware key
                    options.ppx,
                    options.version
                  );

                  window.location.href = url;
                },
                clientKeyNonce: abToB64(rawKey)
              });
            };

            const GCKreject = () => {};

            getClientKey(GCKresolve, GCKreject);
          } else {
            wwpassAuth({
              ticket,
              callback: (status, reason) => {
                const url = getCallbackScheme(
                  options.callbackURL,
                  status,
                  ticket,
                  (status === STATUS.OK ? 'OK' : reason),
                  true, // hw = true, hardware key
                  options.ppx,
                  options.version
                );
                window.location.href = url;
              },
              clientKeyNonce: abToB64(key)
            });
          }
        } else {
          wwpassAuth({
            ticket,
            callback: (status, reason) => {
              const url = getCallbackScheme(
                options.callbackURL,
                status,
                ticket,
                (status === STATUS.OK ? 'OK' : reason),
                true, // hw = true, hardware key
                options.ppx,
                options.version
              );
              window.location.href = url;
            }
          });
        }
      },
      () => {
      }
    );

    e.preventDefault();
  }, false);
  options.log('options', options);
  wwpassQRCodeAuth(options);
};


const openWithTicket = initialOptions => new Promise((resolve, reject) => {
  const away = (options) => {
    window.location.href = getUniversalScheme(options);
  };

  const defaultOptions = {
    ticket: '',
    callbackURL: '',
    ppx: 'wwp_'
  };

  let options = Object.assign({}, defaultOptions, initialOptions);

  if (isClientKeyTicket(options.ticket)) {
    // getKey and open
    const key = loadBuffer();
    if (!key || !abToB64(key)) {
      const GCKresolve = (rawKey) => {
        saveBuffer(rawKey);
        options = Object.assign({}, options, {
          clientKey: encodeClientKey(rawKey)
        });

        away(options);
        resolve();
      };

      const GCKreject = (err) => {
        reject(err);
      };

      getClientKey(GCKresolve, GCKreject);
    } else {
      options = Object.assign({}, options, {
        clientKey: encodeClientKey(key)
      });

      away(options);
      resolve();
    }
  } else {
    // open
    away(options);
    resolve();
  }
});

if ('console' in window && window.console.log) {
  window.console.log(`WWPass universal library version ${version}`);
}

window.WWPass = {
  init,
  openWithTicket,
  onKeyRemoved,
  pluginPresent,
  STATUS
};

window.WWPassCrypto = {
  initWithTicket: ticket => new WWPassCrypto(ticket),
  promiseWithTicket: ticket => new WWPassCryptoPromise(ticket)
};
