import QRRender from './qrcode';
import PageVisibility from './PageVisibility';
import { wwpassWebSocket } from './wwpass.websocket';
import { abToB64 } from '../ab';
import isClientKeyTicket from './../ticket';
import {
  getClientKey,
  encodeClientKey,
  saveBuffer,
  loadBuffer
} from './../crypto';

import {
  getUniversalScheme
} from './../scheme';

const pageVisibility = new PageVisibility();
export const getJSON = function getJSON(url, successHandler, errorHandler) {
  const xhr = new XMLHttpRequest();
  xhr.open('get', url, true);
  xhr.addEventListener('readystatechange', () => {
    let status;
    let data;

    // https://xhr.spec.whatwg.org/#dom-xmlhttprequest-readystate
    if (xhr.readyState === 4) { // `DONE`
      status = xhr.status;
      if (status === 200) {
        data = JSON.parse(xhr.responseText);
        if (successHandler) successHandler(data);
      } else
        if (errorHandler) errorHandler(status);
    }
  });
  xhr.send();
};

/*
 * WWPass QR code auth function
 *
options = {
    'ticket': undefined, // string
    'ticketURL': undefined, // string
    'callback' : undefined , // function
    'callbackURL': undefined, // string
    'development': false, // work with dev server
    'log': function (message) || console.log, // another log handler
    'render': true, false or #id // render qrcode with UI
    'echo': undefined
}
 */
export const wwpassQRCodeAuth = (initialOptions) => {
  const qrcode = {};

  qrcode.defaultOptions = {
    universal: false,
    clientKey: undefined,
    ticketURL: undefined,
    callbackURL: undefined,
    development: false,
    version: 2,
    render: true, // false, #id
    echo: undefined,
    ppx: 'wwp_',
    spfewsAddress: 'wss://spfews.wwpass.com',
    log: () => {}
  };

  qrcode.options = Object.assign({}, qrcode.defaultOptions, initialOptions);
  qrcode.connection = [];
  qrcode.timer = null;
  qrcode.isWork = true;

  qrcode.options.render = qrcode.options.qrcode;

  const log = qrcode.options.log;
  const getScheme = (ticket, callbackURL, ppx = 'wwp_', version = 2, enClientKey = undefined) => {
    let scheme = 'wwpass://auth';
    scheme += `?v=${version}`;
    scheme += `&t=${encodeURIComponent(ticket)}`;
    scheme += `&ppx=${encodeURIComponent(ppx)}`;
    if (enClientKey) {
      scheme += `&ck=${enClientKey}`;
    }

    if (callbackURL !== undefined) {
      scheme += `&c=${encodeURIComponent(callbackURL)}`;
    }

    return scheme;
  };

  qrcode.click = (enClientKey) => {
    qrcode.isWork = !qrcode.isWork;
    if (qrcode.isWork) {
      qrcode.init();
    } else {
      const s = qrcode.connection;

      while (s.length) {
        const i = s.shift();
        log(`Socket is open ?: ${(i.readyState === WebSocket.OPEN)
                    .toString()}`);
        if (i.readyState === WebSocket.OPEN) {
          i.close();
        }
      }

      if (qrcode.timer) {
        log('Stop timer');
        clearTimeout(qrcode.timer);
      }

      const mobileDevice = (
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/Android/i)
      );

      if (qrcode.options.callbackURL && mobileDevice) {
        // const url = getScheme(
        //   qrcode.ticket,
        //   qrcode.options.callbackURL,
        //   qrcode.options.ppx,
        //   qrcode.options.version,
        //   enClientKey
        // );
        //
        const url = getUniversalScheme({
          universal: qrcode.options.universal,
          ticket: qrcode.ticket,
          callbackURL: qrcode.options.callbackURL,
          ppx: qrcode.options.ppx,
          version: qrcode.options.version,
          clientKey: enClientKey
        });

        qrcode.options.log('open url', url);

        window.location.href = url;
      }
    }

    qrcode.renderID.classList.toggle('focused');
  };

  qrcode.init = () => {
    let QRRoot = qrcode.options.qrcode || document.getElementById(qrcode.options.render) || document.getElementById('qrcode') || undefined;
    let forBlur;
    if (typeof (qrcode.options.render) === 'object') {
      forBlur = qrcode.options.render;
    } else {
      forBlur = document.getElementById(qrcode.options.render) ||
                    document.getElementById('_wwpass_qrcode');
    }

    if (typeof (forBlur) === 'object' && forBlur.querySelector) {
      const elem = forBlur.querySelector('img');
      if (elem) {
        elem.style.transition = 'all .4s ease-out';
        elem.style.opacity = '.3';
      }
    }

    if (qrcode.options.ticketURL !== undefined) {
      log(`Get ticket from ${qrcode.options.ticketURL}`);
      getJSON(qrcode.options.ticketURL, (json) => {
        const ticket = json.ticket || json.data;
        const ttl = json.ttl || 120;

        qrcode.ticket = ticket;
        log(ticket);
        log(`isClientKeyTicket ${isClientKeyTicket(ticket)}`);
        if (isClientKeyTicket(ticket)) {
          log('isClientKeyTicket True');

          const key = loadBuffer();

          if (!key || !abToB64(key)) {
            const GCKresolve = (rawKey) => {
              saveBuffer(rawKey);
              const socketIns = wwpassWebSocket({
                callbackURL: qrcode.options.callbackURL,
                ticket,
                log: qrcode.options.log,
                development: qrcode.options.development,
                version: qrcode.options.version,
                ppx: qrcode.options.ppx,
                spfewsAddress: qrcode.options.spfewsAddress
              });

              qrcode.connection.push(socketIns);

              QRRoot = qrcode.options.qrcode;
              QRRoot.innerHTML = '';

              qrcode.renderID = QRRoot;

              const scheme = getUniversalScheme({
                ticket,
                clientKey: encodeClientKey(rawKey)
              });

              log(`QR Code scheme: ${scheme}`);
              QRRender(QRRoot, scheme);

              QRRoot.addEventListener('click', () => {
                const enClientKey = encodeClientKey(rawKey);
                qrcode.click(enClientKey);
              });

              qrcode.timer = setTimeout(() => {
                log('timer triggered');
                pageVisibility.whenVisible(() => {
                  log('PageVisibility triggered');
                  qrcode.init();
                });
              }, ttl * 900);
            };

            const GCKreject = () => {};

            getClientKey(GCKresolve, GCKreject);
          } else {
            const socketIns = wwpassWebSocket({
              callbackURL: qrcode.options.callbackURL,
              ticket,
              log: qrcode.options.log,
              development: qrcode.options.development,
              version: qrcode.options.version,
              ppx: qrcode.options.ppx,
              spfewsAddress: qrcode.options.spfewsAddress
            });

            qrcode.connection.push(socketIns);

            QRRoot = qrcode.options.qrcode;
            QRRoot.innerHTML = '';

            qrcode.renderID = QRRoot;

            const scheme = getUniversalScheme({
              ticket,
              clientKey: encodeClientKey(key)
            });

            log(`QR Code scheme: ${scheme}`);
            QRRender(QRRoot, scheme);

            QRRoot.addEventListener('click', () => {
              qrcode.click(encodeClientKey(key));
            });

            qrcode.timer = setTimeout(() => {
              log('timer triggered');
              pageVisibility.whenVisible(() => {
                log('PageVisibility triggered');
                qrcode.init();
              });
            }, ttl * 900);
          }
        } else {
          // work wothout clientKeyNonce
        }
      });
    } // ticket
  }; // qrcode.init

  qrcode.init();

  return qrcode;
};
